import { useState } from "react";
import Stripe from "stripe";
import { useTeamAccount } from "~/providers/TeamAccountProvider";
import { api, RouterOutputs } from "~/server/trpc/react";
import getStripe from "~/app/api/webhooks/stripe/client";
export interface ConfirmChangesState {
  state: {
    priceId: string;
    productId: string;
    currentPlanName: string | undefined;
    preparedUpdatedPlan: {
      name: string;
      price: number;
      currency: string;
      subscriptionId: string | undefined;
    };
    priceProrationAdjustment: {
      adjustment: number;
      currency: string;
      dueAmount: number;
    };
    customerPaymentDetails: {
      brand: string | undefined;
      last4: string | undefined;
    }; // This could be more specific based on the actual data structure
    prorationDate: number;
    newPriceTotalContacts: number;
    isPlanDowngrade: boolean;
    currentSubscription: Stripe.Subscription | null;
    anchorDate: number | null;
  } | null;
}

interface SubscriptionManagementHook {
  activeSubscriptionPlan: RouterOutputs["teamAccount"]["getActiveTeamAccountUserTeamAccounts"][number]["stripeSubscription"];
  choicedPlanPriceId: string | null;
  setChoicedPlanPriceId: (value: string | null) => void;
  isUpdatingPlan: boolean;
  showNeedAdminModal: boolean;
  setShowNeedAdminModal: (value: boolean) => void;
  showConfirmationPlanModal: boolean;
  setShowConfirmationPlanModal: (value: boolean) => void;
  confirmChangesData: ConfirmChangesState;
  showDeleteContactsModal: {
    isOpen: boolean;
    newPriceTotalContacts: number;
    currentContactCount: number;
  };
  setShowDeleteContactsModal: (value: {
    isOpen: boolean;
    newPriceTotalContacts: number;
    currentContactCount: number;
  }) => void;
  handleSubscriptionUpdate: () => Promise<void>;
  handleGoToSubscriptionCheckoutSession: (
    priceUnitAmount: number,
    priceId: string
  ) => Promise<void>;
}

export const useSubscriptionManagement = (
  closeSubscriptionPlansModal: () => void
): SubscriptionManagementHook => {
  const [confirmChangesData, setConfirmChangesData] =
    useState<ConfirmChangesState>({
      state: null,
    });
  const [showConfirmationPlanModal, setShowConfirmationPlanModal] =
    useState(false);
  const [showDeleteContactsModal, setShowDeleteContactsModal] = useState<{
    isOpen: boolean;
    newPriceTotalContacts: number;
    currentContactCount: number;
  }>({
    isOpen: false,
    newPriceTotalContacts: 0,
    currentContactCount: 0,
  });
  const [choicedPlanPriceId, setChoicedPlanPriceId] = useState<string | null>(
    null
  );
  const [isUpdatingPlan, setIsUpdatingPlan] = useState(false);
  const [showNeedAdminModal, setShowNeedAdminModal] = useState(false);

  const productPrices = api.stripe.getProductPrices.useQuery({}).data;

  const { activeTeamAccount, teamAccessLevel, setTeamAccounts } =
    useTeamAccount();
  const { mutateAsync: createStripeCheckoutSession } =
    api.stripe.createStripeCheckoutSession.useMutation({});
  const { mutateAsync: updateStripeSubscription } =
    api.stripe.updateStripeSubscription.useMutation();
  const { mutateAsync: prepareSubscriptionUpdate } =
    api.stripe.prepareSubscriptionUpdate.useMutation();
  const { data: currentContactCount } =
    api.teamAccount.getCurrentContactCount.useQuery({
      teamAccountId: activeTeamAccount?.id ?? "",
    });

  const activeStripeSubscription = activeTeamAccount?.stripeSubscription;
  const isNotTeamOwnerOrAdmin =
    teamAccessLevel !== "owner" && teamAccessLevel !== "admin";

  const handleSubscriptionUpdate = async () => {
    console.log("handling subscription update");
    if (confirmChangesData.state === null) return;
    setIsUpdatingPlan(true);
    console.log("confirmChangesData", confirmChangesData);
    try {
      await updateStripeSubscription({
        priceId: confirmChangesData.state.priceId,
        productId: confirmChangesData.state.productId,
        stripeSubscriptionId: activeStripeSubscription?.subscriptionId ?? "",
        prorationDate: confirmChangesData.state.prorationDate,
        teamAccountId: activeTeamAccount?.id ?? "",
        newTotalContacts: confirmChangesData.state.newPriceTotalContacts,
        isPlanDowngrade: confirmChangesData.state.isPlanDowngrade,
        anchorDate: confirmChangesData.state.anchorDate ?? 0,
      });
    } catch (error) {
      console.error("Error during subscription update:", error);
    } finally {
      console.log("updating plan");
      setIsUpdatingPlan(false);
      setChoicedPlanPriceId(null);
      setShowConfirmationPlanModal(false);
      // setTeamAccounts will refetch the activeTeamAccount and update the activeSubscriptionPlan
      setTeamAccounts().catch(console.error);
      closeSubscriptionPlansModal();
      console.log("updating plan done");
    }
  };

  const handleGoToSubscriptionCheckoutSession = async (
    priceUnitAmount: number,
    priceId: string
  ) => {
    console.log("handling go to subscription checkout session");
    if (isNotTeamOwnerOrAdmin) {
      setShowNeedAdminModal(true);
      return;
    }

    try {
      setChoicedPlanPriceId(priceId);
      console.log("selected product");
      console.log("product prices", productPrices);
      const selectedProduct = productPrices?.find((product) =>
        product.prices.some((price) => price.priceId === priceId)
      );
      console.log("selected product", selectedProduct);
      if (!selectedProduct) return;

      // get the metadata from the price instead of the product
      const selectedPrice = selectedProduct.prices.find(
        (price) => price.priceId === priceId
      );
      console.log("selected price", selectedPrice);
      const newPriceTotalContacts = selectedPrice?.credits ?? 0;
      console.log("new price total contacts", newPriceTotalContacts);
      if ((currentContactCount ?? 0) > newPriceTotalContacts) {
        setShowDeleteContactsModal({
          isOpen: true,
          newPriceTotalContacts,
          currentContactCount: currentContactCount ?? 0,
        });
        return;
      }

      // Handle different subscription states:
      // 1. Free trial period (status === "trialing")
      // 2. Inactive paid subscription
      // Both cases require redirecting to Stripe checkout instead of updating existing subscription
      if (
        activeStripeSubscription?.status === "trialing" ||
        !activeStripeSubscription?.isActive
      ) {
        const checkoutSession = await createStripeCheckoutSession({
          priceId,
          stripeCustomerId:
            activeTeamAccount?.stripeSubscription?.stripeCustomerId ?? "",
        });

        const stripe = await getStripe();
        await stripe?.redirectToCheckout({
          sessionId: checkoutSession.sessionId,
        });
      } else {
        const subscriptionUpdateInfo = await prepareSubscriptionUpdate({
          customerId: activeStripeSubscription?.stripeCustomerId ?? "",
          subscriptionId: activeStripeSubscription?.subscriptionId ?? "",
          priceId,
          priceUnitAmount,
        });

        const {
          priceProrationAdjustment,
          customerPaymentDetails,
          prorationDate,
          isPlanDowngrade,
          currentSubscription,
          anchorDate,
        } = subscriptionUpdateInfo;

        setConfirmChangesData({
          state: {
            priceId,
            productId: selectedProduct.productId,
            currentPlanName:
              activeTeamAccount?.stripeSubscription?.price.product.name,
            preparedUpdatedPlan: {
              name: selectedProduct.name,
              price: priceUnitAmount,
              currency: selectedProduct.prices[0].currency,
              subscriptionId: activeStripeSubscription?.subscriptionId,
            },
            priceProrationAdjustment: priceProrationAdjustment ?? {
              adjustment: 0,
              currency: "",
              dueAmount: 0,
            },
            customerPaymentDetails: customerPaymentDetails ?? {
              brand: undefined,
              last4: undefined,
            },
            prorationDate: prorationDate ?? 0,
            newPriceTotalContacts,
            isPlanDowngrade,
            currentSubscription,
            anchorDate: anchorDate ?? 0,
          },
        });
        setShowConfirmationPlanModal(true);
      }
    } catch (error) {
      console.error("Error during subscription checkout session:", error);
    } finally {
      setChoicedPlanPriceId(priceId);
    }
  };
  const activeSubscriptionPlan = activeTeamAccount?.stripeSubscription;
  return {
    activeSubscriptionPlan,
    choicedPlanPriceId,
    setChoicedPlanPriceId,
    showDeleteContactsModal,
    setShowDeleteContactsModal,
    isUpdatingPlan,
    showNeedAdminModal,
    setShowNeedAdminModal,
    showConfirmationPlanModal,
    setShowConfirmationPlanModal,
    confirmChangesData,
    handleSubscriptionUpdate,
    handleGoToSubscriptionCheckoutSession,
  };
};
