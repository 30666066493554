import { AlertCircle } from "lucide-react";
import { Alert, AlertDescription, AlertTitle } from "~/components/ui/alert";
import NavBar from "../layout/NavBar";
import SubscriptionPlans from "../settings/SubscriptionPlans";

const FREE_TRIAL_NAME_STRING = "Free Trial";
const OPENQ_SUPPORT_EMAIL = "info@openq.dev";

export default function CanceledSubscriptionPage({
  productName,
}: {
  productName: string;
}) {
  const alertTitle = productName?.includes(FREE_TRIAL_NAME_STRING)
    ? `Your free trial has expired!`
    : `Your subscription has been terminated!`;

  const alertDescription = productName?.includes(FREE_TRIAL_NAME_STRING)
    ? "Please choose a plan below to continue using our service"
    : "To restore access to all features, please choose a plan below";
  return (
    <div>
      <NavBar />
      <div className="absolute top-10 left-0 right-0 flex flex-col items-center content-center justify-center">
        <div className="flex flex-col justify-center items-center w-100">
          <div className="flex flex-col pb-10">
            <Alert variant="default" className="pb-5 pr-5">
              <AlertCircle className="h-5 w-5" />
              <AlertTitle className="text-lg">{alertTitle}</AlertTitle>

              <AlertDescription className="flex flex-col text-base w-100">
                {alertDescription}
              </AlertDescription>
            </Alert>
            <span className="mt-1 text-xs text-muted-foreground">
              Need help? Contact our support team at{" "}
              <a
                href={`mailto:${OPENQ_SUPPORT_EMAIL}`}
                className="font-medium underline"
              >
                {OPENQ_SUPPORT_EMAIL}
              </a>
            </span>
          </div>
        </div>

        <SubscriptionPlans />
      </div>
    </div>
  );
}
