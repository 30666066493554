import { cn } from "~/lib/utils";
import {
  Select,
  SelectContent,
  SelectValue,
  SelectTrigger,
  SelectItem,
} from "../ui/select";
import { useEffect, useState } from "react";
import { Check } from "lucide-react";
import { Button } from "../ui/button";
import { Separator } from "../ui/separator";

export function getPriceString(
  price: number | null | undefined,
  currency: string | null | undefined
) {
  if (!price || !currency) {
    return "€0";
  }

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 0,
  }).format(price / 100);
}

export default function PricingCard({
  plan,
  isAnnual,
  handleGoToSubscriptionCheckoutSession,
  activePriceId,
}: {
  plan: any;
  isAnnual: boolean;
  handleGoToSubscriptionCheckoutSession: (
    priceUnitAmount: number,
    priceId: string
  ) => Promise<void>;
  activePriceId: string;
}) {
  const [selectedPrice, setSelectedPrice] = useState<any>(null);

  const prices = plan.prices
    .filter(
      (price: any) =>
        price.pricingPlanInterval === (isAnnual ? "year" : "month")
    )
    .sort((a: any, b: any) => a.unitAmount - b.unitAmount);

  // reset selected price when toggling on the UI between annual and monthly
  useEffect(() => {
    setSelectedPrice(null);
  }, [isAnnual]);

  const getDisplayPlanName = (planName: string) => {
    if (planName.includes("Starter")) {
      return "Starter";
    }
    if (planName.includes("Explorer")) {
      return "Explorer";
    }
    if (planName.includes("Pro")) {
      return "Pro";
    }
    return planName;
  };
  return (
    <div
      className={cn(
        "w-[350px] relative rounded-2xl border p-8 shadow-sm transition-shadow hover:shadow-md",
      )}
    >
      <div className="mb-6">
        <h3 className="mb-4 text-4xl font-bold">{getDisplayPlanName(plan.name)}</h3>
        <p className="mb-1 text-2xl font-semibold">
          {selectedPrice
            ? getPriceString(
                selectedPrice?.unitAmount,
                selectedPrice?.currency ?? "eur"
              )
            : getPriceString(
                prices[0]?.unitAmount,
                prices[0]?.currency ?? "eur"
              )}
          /
          {selectedPrice
            ? selectedPrice?.pricingPlanInterval
            : prices[0]?.pricingPlanInterval}
        </p>
        <p className="text-sm text-muted-foreground">
          Per{" "}
          {selectedPrice
            ? selectedPrice?.pricingPlanInterval
            : prices[0].pricingPlanInterval}
          , billed{" "}
          {selectedPrice
            ? selectedPrice?.pricingPlanInterval === "month"
              ? "monthly"
              : "yearly"
            : prices[0]?.pricingPlanInterval === "month"
              ? "monthly"
              : "yearly"}
        </p>
      </div>
      <div className="mt-2 mb-4">
        <p className="mb-2 text-lg">{plan.description}</p>

        <Select
          value={selectedPrice?.id || ""}
          onValueChange={(value) => {
            setSelectedPrice(prices.find((price: any) => price.id === value));
          }}
        >
          <SelectTrigger>
            <SelectValue placeholder="Select credits" />
          </SelectTrigger>
          <SelectContent>
            {prices.map((price: any) => (
              <SelectItem
                key={price.id}
                value={price.id}
                disabled={activePriceId === price.id}
              >
                {price.credits} credits -{" "}
                {getPriceString(price.unitAmount, price.currency ?? "eur")}/
                {price.pricingPlanInterval}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
      <div className="mt-6 w-full space-y-4">
        <Button
          className="w-full"
          variant={selectedPrice ? "default" : "outline"}
          disabled={!selectedPrice}
          onClick={() =>
            handleGoToSubscriptionCheckoutSession(
              selectedPrice?.unitAmount,
              selectedPrice?.priceId
            )
          }
        >
          {selectedPrice ? "Upgrade Plan" : "Select Credits"}
        </Button>
      </div>
      <Separator className="mt-4 mb-4" />

      <ul className="mb-8 space-y-3">
        {plan.marketingFeatures?.map((feature: any, index: any) => (
          <li key={index} className="flex items-center gap-2">
            <Check className="h-5 w-5 text-primary" />
            <span>{feature?.name}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}
