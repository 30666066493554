"use client";

import { useState } from "react";
import { Badge } from "~/components/ui/badge";
import { Switch } from "~/components/ui/switch";
import { Label } from "~/components/ui/label";
import { api, RouterOutputs } from "~/server/trpc/react";
import { useTeamAccountUser } from "~/providers/TeamAccountUserProvider";
import { UpdateActivePlanConfirmationModal } from "./UpdateActivePlanConfirmationModal";
import DeleteContactsModal from "./DeleteContactsModal";
import { NeedAdminForUpgradeModal } from "./NeedAdminForUpgradeModal";
import { useSubscriptionManagement } from "./hooks/useSubscriptionManagement";
import PricingCard from "./PricingCard";
import { Skeleton } from "../ui/skeleton";


const STARTER_PLAN_NAME = "Starter";
const EXPLORER_PLAN_NAME = "Explorer";
const PRO_PLAN_NAME = "Pro";
const TEST_PLAN_NAME = "Test";


function gatherSubscriptionPlans(
  productPrices: RouterOutputs["stripe"]["getProductPrices"],
  isAdminUser: boolean
) {
  const plans = [];
  for (const product of productPrices) {
    if (product.name.includes(STARTER_PLAN_NAME)) {
      plans.push(product);
    } else if (product.name.includes(EXPLORER_PLAN_NAME)) {
      plans.push(product);
    } else if (product.name.includes(PRO_PLAN_NAME)) {
      plans.push(product);
    } else if (product.name.includes(TEST_PLAN_NAME) && isAdminUser) {
      plans.push(product);
    }
  }

  return plans;
}

export default function SubscriptionPlans({
  closeSubscriptionPlansModal = () => {},
}: {
  closeSubscriptionPlansModal?: () => void;
}) {
  const {
    activeSubscriptionPlan,
    isUpdatingPlan,
    setChoicedPlanPriceId,
    showDeleteContactsModal,
    setShowDeleteContactsModal,
    showNeedAdminModal,
    setShowNeedAdminModal,
    showConfirmationPlanModal,
    setShowConfirmationPlanModal,
    confirmChangesData,
    handleSubscriptionUpdate,
    handleGoToSubscriptionCheckoutSession,
  } = useSubscriptionManagement(closeSubscriptionPlansModal);

  const [isAnnual, setIsAnnual] = useState(false);
  const { teamAccountUser } = useTeamAccountUser();
  const isAdminUser = teamAccountUser?.role === "admin";

  const { data: productPrices = [], isLoading: isLoadingProductPrices } =
    api.stripe.getProductPrices.useQuery<
      RouterOutputs["stripe"]["getProductPrices"]
    >({});
  console.log("product prices", productPrices);
  const plans = gatherSubscriptionPlans(productPrices, isAdminUser);
  console.log("plans", plans);
  console.log(plans);
  const planOrder = ["OpenQ - DRM Starter", "OpenQ - DRM Explorer", "OpenQ - DRM Pro", "OpenQ - DRM Test"];
  const sortedPlans = plans.sort((a, b) => {
    const aIndex = planOrder.indexOf(a.name);
    const bIndex = planOrder.indexOf(b.name);
    return aIndex - bIndex;
  });

  if (isLoadingProductPrices) {
    return (
      <div className="w-full px-6 py-8">
        <div className="mb-12 flex flex-col items-center gap-2">
          <Skeleton className="h-10 w-[280px]" />
          <Skeleton className="h-5 w-[400px]" />
          <Skeleton className="mt-3 h-7 w-[200px]" />
        </div>
        <div className="grid gap-12 md:grid-cols-3">
          <Skeleton className="h-[320px] w-full" />
          <Skeleton className="h-[320px] w-full" />
          <Skeleton className="h-[320px] w-full" />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="px-6 pt-8 pb-0">
        <div className="mb-12 text-center">
          <h2 className="text-3xl font-bold tracking-tight">
            Subscription Plans
          </h2>
          <p className="mt-2 text-muted-foreground">
            Choose the perfect plan for your team's needs
          </p>
          <div className="mt-6 flex items-center justify-center gap-4">
            <Label htmlFor="billing-toggle" className="text-sm">
              Monthly
            </Label>
            <Switch
              id="billing-toggle"
              checked={isAnnual}
              onCheckedChange={setIsAnnual}
            />
            <div className="flex items-center gap-2">
              <Label htmlFor="billing-toggle" className="text-sm">
                Annual
              </Label>
              <Badge variant="secondary">Save 15%</Badge>
            </div>
          </div>
        </div>
        <div className="grid gap-12 md:grid-cols-3">
          {sortedPlans?.length > 0 &&
            sortedPlans.map((plan) => (
              <PricingCard
                key={plan.id}
                plan={plan}
                isAnnual={isAnnual}
                handleGoToSubscriptionCheckoutSession={
                  handleGoToSubscriptionCheckoutSession
                }
                activePriceId={activeSubscriptionPlan?.priceId ?? ""}
              />
            ))}
        </div>

        <p className="mt-4 text-center text-sm text-muted-foreground">
          Interested in enterprise features like dedicated support, higher
          limits, and unlimited contacts and enhancements?{" "}
          <a
            href="/contact-us"
            className="font-medium underline underline-offset-4"
          >
            Contact us
          </a>
        </p>
      </div>
      <NeedAdminForUpgradeModal
        isOpen={showNeedAdminModal}
        onClose={() => setShowNeedAdminModal(false)}
      />
      <UpdateActivePlanConfirmationModal
        isAnnual={isAnnual}
        isOpen={showConfirmationPlanModal && confirmChangesData.state !== null}
        confirmChangesData={confirmChangesData.state}
        onClose={() => {
          setChoicedPlanPriceId(null);
          setShowConfirmationPlanModal(false);
        }}
        handleSubscriptionUpdate={handleSubscriptionUpdate}
        isUpdatingPlan={isUpdatingPlan}
      />

      <DeleteContactsModal
        isOpen={showDeleteContactsModal.isOpen}
        newPriceTotalContacts={showDeleteContactsModal.newPriceTotalContacts}
        currentContactCount={showDeleteContactsModal.currentContactCount}
        onOpenChange={(open: boolean) => {
          setShowDeleteContactsModal({
            ...showDeleteContactsModal,
            isOpen: open,
          });
          setChoicedPlanPriceId(null);
        }}
      />
    </>
  );
}
